.locationSearch {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.locationSearch {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    /*padding-bottom: 20px;*/
}

.locationSearchField {
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    z-index: 3;
    /*background-color: hotpink;*/
    /*display: flex;*/
    /*align-items: flex-end;*/
    /*justify-content: flex-end;*/

    position: fixed;
    top: .5rem;
    left: 4.5rem;
    right: .5rem;

}

.locationSearch .textInput {

    margin: unset;
    float: right;
}

.locationSearch input {
    border: 1px solid;
    padding: 10px;
    border-radius: 8px;
    font-size: 18px;
    background-color: var(--secondary);
    width: calc(100vw - 5rem);
}

.locationSearch__results {
    overflow: auto;
    float: right;
    width: calc(100vw - 3.7rem);
    border-radius: 8px;

    max-height: 30vh;
}

.locationSearch__result {
    width: calc(100% - .8rem);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    background-color: var(--secondary-low-contrast);

    color: var(--primary);
    padding: .5rem;
    margin: .4rem;

    border-radius: .4rem;
}

.locationSearch .textInput__label {
    color: var(--primary-high-contrast)
}


.locationSearch__result.active {
    border: 1px solid var(--active-color);
    background-color: var(--active-color-faded);
}

.locationSearch .mapDiv {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}


.locationSearch .buttonWrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
}

.locationSearch .confirmButton svg {
    height: 4rem;
    width: 4rem;
}
