.deleteNode {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--secondary);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 11;
}

.deleteNode svg {
    --size: min(40vh, 80vw);
    height: var(--size);
    width: var(--size);
}
