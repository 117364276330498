.navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;
    transition: bottom 300ms ease-in;
}

.hidden.navigation {
    bottom: -90px;
}

.navigation .main {
    display: flex;
}

.navigation,
.navigation .main {
    pointer-events: none;
}


.navButton {
    position: relative;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 48px;

}

.navButton > .icon,
.navButton > .navButtonBg {
    pointer-events: none;
}

.navButton > .navButtonBg rect,
.navButton > .navButtonBg path {
    pointer-events: all;
}
.navButton > .navButtonBg {
    fill: var(--secondary-low-contrast);
}

.navButton.active > .navButtonBg {
    fill: var(--active-color);
}

.navButton .navButtonBg {
    position: absolute;
    z-index: 2;
}

.navButton .icon {
    height: 3rem;
    width: 3rem;
    z-index: 3;
}

.navButton.right .icon {
    margin-left: -.7rem;
    margin-bottom: -.7rem;
}

.navButton.left .icon {
    margin-right: -.7rem;
    margin-bottom: -.7rem;
}

.navWrapper {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
    z-index: 9;
}

.navButtonBg.flip {
    transform: rotateY(180deg);
}

.navWrapper > * {
    pointer-events: all;
}

.centralButton {
    transition: opacity 200ms ease-in;
}

.hidden.centralButton {
    opacity: 0;
    pointer-events: none;
}