.node {
    position: absolute;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 200ms ease-in;
    z-index: 10;
}

.new.node {
    background-color: var(--secondary);
}

.hidden.node {
    opacity: 0;
    pointer-events: none;
}

.node svg {
    height: 80%;
    width: 80%;
    z-index: 3;
}

.locationContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2em;
}

.results__centralButton.fetching svg {
    animation: spin 1s infinite linear reverse;
}


.node__label-wrapper {
    position: absolute;
    height: 0;
    display: flex;
    justify-content: center;
}

.node--label {
    flex-direction: column;
}

.node__label {
    z-index: 2;
    position: absolute;
    top: 2.5rem;
    text-align: center;
}

.node--small .node__label {
    top: 1.5rem;
}

.node--red svg {
    fill: red;
}

.spin {
    animation: spin 1s infinite linear;
}

.selected {
    animation: red-pulse 2s infinite linear;
}

@keyframes red-pulse {
    0% {
        background-color: rgba(255, 0, 0, 0.7);
    }
    50% {
        background-color: rgba(255, 0, 0, 0.5);
    }
    100% {
        background-color: rgba(255, 0, 0, 0.7);
    }
}

@keyframes blue-pulse {
    0% {
        background-color: rgba(0, 202, 255, 0.7);
    }
    50% {
        background-color: rgba(0, 202, 255, 0.5);
    }
    100% {
        background-color: rgba(0, 202, 255, 0.7);
    }
}

.mapToggle {
    position: fixed;
    right: 10px;
    bottom: 10px;
}
