
.centralButtonWrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;
    pointer-events: none;
    z-index: 1;
}

.centralButton {
    pointer-events: all;
    --size: 60px;
    height: var(--size);
    width: var(--size);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--secondary-low-contrast);
}

.centralButton.gpsButton {
    margin: -4rem auto 0 auto;
}

.centralButton svg {
    --size: 48px;
    height: var(--size);
    width: var(--size);

    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

