.monks {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.monks svg {
    width: min(60vh, 95vw)
}

/*.monks line.glow {*/
.monks line {
    stroke: red;
}

.monks line.white {
    stroke: white;
}