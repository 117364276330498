.settings {
    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.settingsContent {
    overflow: auto;
    padding: 1rem 1rem 100px 1rem;
    max-height: calc(100vh - 140px);
}

.settingsContent p {
    margin: 0;
}

.settingsContent > div {
    text-align: left;
    max-height: calc(100vh - 80px);
    padding: 1rem 0 13rem 0;
}

.settingsContent .toggleSwitch {
    margin: 2px 0;
}

.settings .toggleSwitch svg {
    min-width: 55px;
}

.settings .toggleSwitch span {
    text-align: left;
}

.walkspeedControls {
    display: flex;
    justify-content: flex-start;
    margin: 1rem 0 2rem 0;
}

.walkspeedControls button {
    margin-left: 1rem;
}

.walkspeedControls input {
    width: 100vw;
    accent-color: var(--active-color);
}

.infoButton {
    font-size: 1.5rem;
    background-color: var(--active-color);
    color: var(--primary-high-contrast);
    padding: .5rem 1.1rem .5rem .8rem;

    display: block;
    margin: 0 auto;
}

.infoButton .icon {
    margin-right: .5rem;
    margin-bottom: -.3rem;
}


.importExportLocations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.importExportLocations button {
    background-color: dodgerblue;
    padding: 1rem;
    color: white;
    margin: 1rem;
}
