/*
    TEXT INPUT
*/

.textInput {
    position: relative;
    margin: 0 auto;
}

.textInput__label {
    position: absolute;
    left: 5px;
    top: -12px;
    font-size: .8em;
    background-color: var(--secondary);
    padding: 0 4px 0 2px;
}

.textInput input {
    background-color: unset;
    border-style: solid;
    padding: 4px 6px;
    border-radius: 3px;
}


/*
    BUTTON
*/

.button {
    padding: 5px 10px;
}

/*RADIO*/

.radioGroup {
    margin: 8px 0;
}

.radioGroup svg {
    width: 35px;
    min-width: 35px;
    height: 35px;
    min-height: 35px;
}

.radioGroup .mainShape {
    fill: var(--primary)
}
.radioGroup .pip {
    fill: var(--active-color)
}
