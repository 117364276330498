
.countdownScreen {
    position: relative;
    background-color: var(--secondary-high-contrast);
    z-index: 401;
    color: var(--primary-high-contrast);
}

/*.countdownScreen.searching {*/
/*    background-color: dodgerblue !important;*/
/*}*/

.countdownScreen .countdownContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.countdownScreen,
.countdownScreen .countdownContent,
.countdownScreen .hexes {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.countdownScreen__section {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.countdownScreen .countdown {
    font-size: 6rem;
    color: var(--primary-high-contrast);
}

.countdownScreen__button,
.countdownScreen__button:active {
    background-color: unset;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
}



.countdownScreen__backButton {
    --size: 60px;

    height: var(--size);
    width: var(--size);

    position: absolute;
    bottom: 120px;
}

.countdownScreen__backButton svg {
    height: var(--size);
    width: var(--size);
}

.countdownScreen__button svg path {
    fill: var(--primary-high-contrast);
}

.countdownScreen__centralButton {
    height: 30vh;
    width: 30vw;
    max-height: 30vw;
    max-width: 30vh;
    border-radius: 300px;
    outline: none;
}

.countdownScreen__centralButton.fetching svg {
    animation: spin .3s infinite linear reverse;
}
