.fireAnim {
    --size: min(80vh, 80vw);
    height: var(--size);
    width: var(--size);
    /*--time: 700ms;*/
    /*--time: 400ms;*/
    --time: 150ms;
    /*--time: 110ms;*/
    /*--time: 80ms;*/
}

.flame1 {
    fill: #ffcf6f;
    opacity: .5;
}

.flame2 {
    fill: #ffa16f;
    opacity: .6;
}

.fireAnim path {
    transition: d var(--time) linear;
}
