.editNode {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


    color: white;

    overflow-y: auto;
}

.editNodeContent {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

}

.editLocation__icon {
    margin-bottom: 1rem;
}

.editLocation__iconWrapper {
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editLocation__icon svg {
    transition: height 200ms, width 200ms;
    width: 96px;
    height: 96px;
}

.editLocation__icon.small svg {
    width: 48px;
    height: 48px;
}

.nodeLabelField input {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.locationSelect {
    margin: 1rem 0;
}

.locationSelect p {
    margin: 0;
}

.locationSelectButton svg {
    height: 4rem;
    width: 4rem;
}

.locationSelectButton {
    background-color: white;
    height: 4.5rem;
    width: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 1rem;
}

.finalButtons button {
    display: inline-block;
    margin: 1rem;
    padding: .5rem 1rem;
    color: black;
    font-size: 1.4rem;
}

