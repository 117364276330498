.results {
    display: flex;
    align-items: center;
    justify-content: center;
}
.results__moreOptionsButton {
    margin: 10px 0 40px 0;
    padding: 10px 15px 7px 15px;
    outline: none;
}

.results__moreOptionsButton svg {
    height: 40px;
    width: 40px;
}

.results__header {
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    height: 30px;

    display: flex;
    justify-content: center;

    pointer-events: none;
}

.optionList {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding-bottom: 100px;
}

.option {
    background: linear-gradient(var(--primary) 0%, var(--primary-low-contrast) 100%);
    margin: 4px 20px;
    padding: 10px;
    border-radius: .5rem;

    display: flex;
    flex-direction: column;
    font-weight: bolder;
}

.option__startTime {
    font-size: 1.2rem;
}

.option__startTime,
.option__endTime {
    color: var(--secondary);
}

.option__header {
    width: 100%;
    display: flex;
    align-items: center;
}

.option__time {
    flex: 3 4;
}

.option__busNumberContainer {
    flex: 4 3;
}

.option__busNumbers {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    max-width: 50vw;
    border-radius: .3rem;
    padding: 8px 12px;
}

.option__busNumberElement--dash {
    margin: 0 .2rem;
}

.option__busNumberElement--foot,
.option__busNumberElement--foot svg {
    height: 1.125rem;
    width: 1.125rem;
}

.optionStep__mode,
.option__busNumbers {
    background-color: var(--secondary-low-contrast);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

}

.optionDetails {
    padding: 10px 0 0 0;
}

.optionDetails__time {
    display: flex;
    align-items: center;
    justify-content: center;
}

.optionDetails__eta {
    display: flex;
    align-items: center;
    justify-content: center;
}


.optionDetails__etaTime {
    position: relative;
    background-color: black;
    color: white;

    font-size: 1.4rem;

    min-width: 5.5rem;
    min-height: 5.5rem;
    border-radius: 3333rem;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .4rem;
}

.optionDetails__etaLabel {
    position: absolute;
    font-size: .8rem;
    font-weight: normal;
    top: .8rem;
    left: 1.9rem;
}

.optionDetails__element {
    padding: 10px 20px;
}

.optionDetails__duration {
    color: var(--secondary);
}

.optionStep {
    position: relative;
    min-height: 3rem;
    width: calc(100% - 10px);
    background: linear-gradient(var(--primary-low-contrast) 0%, var(--secondary-low-contrast) 100%);
    padding: 5px;
    margin: 5px 0;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.optionStep__mode,
.optionStep__spaceTime {
    min-height: calc(100% - 25px);
}

.optionStep__mode {
    width: 3rem;
    height: 3rem;
    border-radius: .55rem;
    background-color: var(--secondary);
    color: var(--primary-high-contrast);
}

.optionStep__spaceTimeWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.optionStep__spaceTime {
    flex: 1 2;
    display: flex;
    flex-direction: column;

}

.optionStep__placeLabel {
    font-size: .8rem;
    color: var(--primary);
    margin-bottom: .2rem;
}

.optionStep__timeLabel {
    font-size: .9rem;
    color: var(--primary);
}

.optionStep__spaceTime--from {
    margin-right: .2rem;
}

.pathMapDiv {
    height: 60vw;
    border-radius: 10px;
    overflow: hidden;
}

.results .centralButton.active {
    background-color: var(--active-color);
}

.results .centralButton.active svg {
    animation-name: spin;
    animation-duration: 1s;
}

.results .centralButton.noBg {
    background-color: unset;
}

.option__fuse {
    position: relative;
    height: 8px;
    width: 100%;
    margin-top: 4px;
}

.option__fuse div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    /*background-color: red;*/
    background-color: white;
    /*background-color: black;*/
    border-radius: 2px;
}


