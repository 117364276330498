
.selectIcon__iconContainer {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;
}

.selectIcon__header {
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.selectIcon__iconContainer {
    height: calc(100vh - 60px);
    margin-top: 60px;
    overflow: auto;
}

.selectIcon__iconWrapper {
    margin: 3vw;
}

.selectIcon__iconContainer svg {
    --size: min(20vh, 27vw);
    height: var(--size);
    width: var(--size);
}
