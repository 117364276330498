.blobs {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 90px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*overflow-y: auto;*/
}

.blobs .iconz {
    max-width: 100vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}


.deleteOrEditNodeUi {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 7;

    display: flex;
    transition: bottom 300ms ease-in;

    height: 80px;
}

.hidden.deleteOrEditNodeUi {
    bottom: -90px;
}

.deleteOrEditNodeUi .dropZone {
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteOrEditNodeUi .dropZone .icon {
    height: 3rem;
    width: 3rem;
}

.editNodeDropZone {
    flex: 2;
    background-color: var(--neutral);
    border-radius: 1.5rem 0 0 0;
}

.deleteNodeDropZone {
    flex: 1;
    border-radius: 0 1.5rem 0 0;
    background-color: var(--danger)
}

.active.dropZone {
    z-index: 2;
}

.active.editNodeDropZone {
    background-color: var(--neutral-light);
    box-shadow: 0 0 12px 9px var(--neutral-lighter);
    -webkit-box-shadow: 0 0 12px 9px var(--neutral-lighter);
    -moz-box-shadow: 0 0 12px 9px var(--neutral-lighter);
}

.active.deleteNodeDropZone {
    background-color: var(--danger-light);
    box-shadow: 0 0 12px 9px var(--danger-lighter);
    -webkit-box-shadow: 0 0 12px 9px var(--danger-lighter);
    -moz-box-shadow: 0 0 12px 9px var(--danger-lighter);
}