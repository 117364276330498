.gpsLoaderWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;
}

.gpsLoader {
    fill: white;
}

.sat {
    fill: white;
    animation: orbit 4s linear infinite;
}

.sat2 {
    animation-delay: 1s;
}
.sat3 {
    animation-delay: 2s;
}
.sat4 {
    animation-delay: 3s;
}


@keyframes orbit {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(280px, 0);
    }
    70% {
        transform: translate(280px, -100px);
    }
    80% {
        transform: translate(0, -100px);
    }
    100% {
        transform: translate(0, 0);
    }
}
