
.helpScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 80000;
    /*pointer-events: none;*/

    padding: 1rem 1rem 0 1rem;
}

.helpScreen__contentWrapper {
    position: relative;
    background-color: var(--secondary-low-contrast-transparent);
    padding: 20px;
    border-radius: 5px;
    font-size: 1.5rem;
    max-height: calc(100vh - 100px);
    max-width: 400px;
    overflow-y: auto;
}

.solid.helpScreen .helpScreen__contentWrapper {
    background-color: var(--secondary-low-contrast);

}

.helpScreen .icon {
    height: 2rem;
    width: 2rem;
    margin-bottom: -.5rem;
}

.helpScreen .closeButtonWrapper {
    position: sticky;
    top: 0;
    left: 0;
}

.helpScreen .closeFullscreenButton {
    position: absolute;
    top: -15px;
    left: -15px;
}
