.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  --primary-high-contrast: #fff;
  --primary: #ddd;
  --primary-low-contrast: #666;
  --secondary-low-contrast: #333;
  --secondary-low-contrast-transparent: rgba(51, 51, 51, 0.85);
  --secondary: #222;
  --secondary-high-contrast: #000;

  --danger: #ff4c00;
  --neutral: #55d1d5;
  --safe: #00ff7f;

  --danger-light: #ff7c47;
  --neutral-light: #6fddff;
  --safe-light: #56ffa6;

  --danger-lighter: #ffaa88;
  --neutral-lighter: #abeeff;
  --safe-lighter: #84ffbc;


  --green: #29a71b;

  --active-color: #00caff;
  --active-color-faded: #15a8ce;

  background-color: var(--secondary);
  color: var(--primary);
}

* {
  border-color: var(--primary);
  outline: unset;
}

button,
.toggleSwitch,
.radioButton,
.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.textInput {
  border-color: var(--primary);
}

.textInput input {
  color: var(--primary);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.full.screen {
  bottom: 0;
  z-index: 10;
  background-color: var(--secondary);
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.padding2 {
  padding: 2rem;
}

.disabled {
  background-color: #878787;
}

.safe {
  background-color: var(--safe);
  fill: var(--safe);
}

.danger {
  background-color: salmon;
}

button {
  background-color: unset;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 5px;
}

.closeFullscreenButton {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
}

.noWrap {
  white-space: nowrap;
}

.closeFullscreenButton svg {
  fill: var(--primary);
  height: 3rem;
  width: 3rem;
}

.closeFullscreenButton.black svg {
  fill: var(--secondary-low-contrast);
}

