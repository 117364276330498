.mapView {
    flex: 1;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.exitMapModeButton {
    position: fixed;
    left: 10px;
    bottom: 10px;
    z-index: 40000;
    opacity: 1;

    transition: opacity .3s;

    background-color: black;
    color: white;
    font-weight: bold;
    border: none;
    height: 2rem;
    width: 2rem;
    border-radius: .5rem;
    font-size: 1.5rem;
}

.exitMapModeButton--hide {
    opacity: 0;
}

.mapLoaderWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    z-index: 999999;

    display: flex;
    align-items: center;
    justify-content: center;
}

.mapLoader {
    height: 50vh;
    max-height: 50vw;
    width: 50vh;
    max-width: 50vw;

    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
